import React, { Fragment } from "react";
import { Helmet } from "react-helmet";
import { Header } from "../../components/Header";
import { FeaturedArea } from "../../components/FeaturedArea";
import { AboutUs } from "../../components/AboutUs";
import { Services } from "../../components/Services";
import { MyWorkExperience } from "../../components/MyWorkExperience";
import { EducationAndSkills } from "../../components/EducationAndSkills";
import { BottomBar } from "../../components/BottomBar";

const HomePage = () => {
  return (
    <Fragment>
      <Helmet>
        <title>Semih Kokcu Kişisel Blog</title>
      </Helmet>
      <Header></Header>
      <FeaturedArea></FeaturedArea>
      <AboutUs></AboutUs>
      <Services></Services>
      <MyWorkExperience></MyWorkExperience>
      <EducationAndSkills></EducationAndSkills>
      <BottomBar></BottomBar>
    </Fragment>
  );
};

export default HomePage;
