import { ReactComponent as Linkedin } from "../lib/icons/linkedin.svg";
import { ReactComponent as Github } from "../lib/icons/github.svg";

export const social4 = [
  {
    socialIcon: (
      <Linkedin className="h-5 w-5 lg:h-10 lg:w-10 fill-accent hover:fill-accent"></Linkedin>
    ),
    socialLink: "https://www.linkedin.com/in/semih-kokcu-9b1671203/",
    socialTitle: "Linkedin",
  },
  {
    socialIcon: (
      <Github className="h-5 w-5 lg:h-10 lg:w-10 fill-[#3d5a70] hover:fill-accent"></Github>
    ),
    socialLink: "https://github.com/SemihKokcu",
    socialTitle: "Github",
  }
];
