import ExpImg1 from "../assets/images/paycell.png";
import ExpImg2 from "../assets/images/octopus.png";

export const workExp = [
  {
    expCount: "1",
    expDate: "(03.2023 - 07.2023)(06.2022-08.2022)(04.2024- ...)",
    expImg: ExpImg2,
    expTitle: "Octopus",
    expSubTitle: "Full Stack Developer",
    expDesc:
      "Node.js, React.JS for desktop app and Php laravel for backend development",
  },
  {
    expCount: "2",
    expDate: "08.2023- 03.2024",
    expImg: ExpImg1,
    expTitle: "Paycell",
    expSubTitle: "Backend Developer",
    expDesc:
      "Java 8 Rest API with ORM techonologies for paycell app.",
  }
  
];
