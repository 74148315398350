import { ReactComponent as JavaScriptIcon } from "../lib/icons/JavaScript.svg";
import { ReactComponent as JavaIcon } from "../lib/icons/java-svgrepo-com.svg";
import { ReactComponent as ReactIcon } from "../lib/icons/react-svgrepo-com.svg";
import { ReactComponent as SQLIcon } from "../lib/icons/sql-svgrepo-com.svg";
import { ReactComponent as DevOpsIcon } from "../lib/icons/devops-svgrepo-com.svg";
import { ReactComponent as NodeJsIcon } from "../lib/icons/node-js-svgrepo-com.svg";

export const skills = [
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <JavaIcon className="h-10 w-10"></JavaIcon>,
    skillTitle: "Java",
    skillDesc: "Spring Framework, Jpa, Rest API\ Spring Cloud for Microservices",
  },
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <NodeJsIcon className="h-10 w-10"></NodeJsIcon>,
    skillTitle: "Node.JS",
    skillDesc: "Rest API with ExressJs and microservices with RabbitMq",
  },
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <JavaScriptIcon className="h-10 w-10"></JavaScriptIcon>,
    skillTitle: "JS, TS",
    skillDesc: "Javascript specially lib : Electron.js",
  },
  {
    skillBox: "pb-10 sm:pt-5 sm:pb-10 sm:pr-10 border-r-0 sm:border-r-[1px] border-b-[1px] border-[#9bb1cf]",
    skillIcon: <ReactIcon className="h-10 w-10"></ReactIcon>,
    skillTitle: "React.JS",
    skillDesc: "React front development with redux support",
  },
  {
    skillBox: "pb-10 pt-10 sm:pb-5 sm:pt-10 sm:pr-10 border-r-0 sm:border-r-[1px] sm:border-b-0 border-b-[1px] border-[#9bb1cf]",
    skillIcon: <DevOpsIcon className="h-10 w-10"></DevOpsIcon>,
    skillTitle: "DevOps",
    skillDesc: "Azure DevOps, Jira, Confluence, Sonar, Kibana, Git, Bitbucket, Docker",
  },
  {
    skillBox: "pt-10 sm:pb-5 sm:pt-10 sm:pl-10",
    skillIcon: <SQLIcon className="h-10 w-10"></SQLIcon>,
    skillTitle: "Database",
    skillDesc: "SQL, PostgreSQL, MongoDB",
  },
];
